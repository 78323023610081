.loader{
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  animation-name: loadingAnimation;
  animation-duration: .3s;
  animation-timing-function: linear;
  animation-direction: alternate;
}

@keyframes loadingAnimation {
  0% {
    opacity: 1;
    background-color: white;
    pointer-events: auto;
  }
  100%{
    opacity: 0;
    pointer-events: none;
  }
}

.menu .active{
  background-color: rgba(0, 0, 0, 0.04);
}
.main{
  padding: 20px;
}
.page-title{
  padding: 10px 0 30px;
  text-transform: uppercase;
}
th[scope="row"]{
  width: 40px;
}
.header-page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0 30px;
}
.header-page h2{
  padding: 0;
  margin-bottom: 0;
}
.header-page button{
  height: 50px;
}
.worker-page th{
  text-transform: uppercase;
  font-size: 14px;
  vertical-align: middle;
  text-align: center;
}
.worker-page td{
  text-align: center;
  font-size: 17px;
  vertical-align: middle;
}
.worker-page td button{
  padding: 14px;
  max-width: 50px;
  min-width: 50px;
  margin-bottom: 10px;
}
.worker-page td button:last-child{
  margin-bottom: 0px;
}
.worker-page td button span{
  margin: 0;
}
.add-form p{
  display: flex;
  flex-direction: column;
}
.add-form p > span{
  margin-bottom: 14px;
  display: block;
}
.add-form > div{
  display: flex;
  width: 90%;
}
.add-form > div > p{
  width: 33.33%;
  margin-left: 20px;
}
.add-form > div > p:first-child{
  margin-left: 0px;
}
.MuiDrawer-paper{
  height: 100vh!important;
}
.page{
  display: flex;
}
.worker-page tbody td:last-child{
  min-height: 74.8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.worker-page.documents tbody td:nth-child(4){
  flex-direction: row;
  justify-content: center;
}
.worker-page.documents td button{
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.pagination ul {
  display: flex;
  margin: 0 auto;
  padding: 30px 0 10px;
  justify-content: center;
  align-items: center;
}

.pagination ul li {
  margin: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination ul li a {
  text-decoration: none;
  color: #1976d2 !important;
  transition: all .3s;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  justify-content: center;
}

.pagination ul li a:hover{
  background-color: rgba(0, 0, 0, 0.04);
}
.pagination .selected a {
  background-color: #1976d2;
  color: white !important;
  border: 1px solid #1976d2;
}
.pagination .selected a:hover{
  background-color: #1565c0;
}
.copyright{
  padding: 15px 0;
  border-top: 1px solid #ccc;
}
.copyright p{
  margin-bottom: 0;
}
.main{
  min-height: 85vh;
}
a{
  text-decoration: none!important;
}
.icon-card{
  width: 50% !important;
  height: 100% !important;
  fill: cadetblue!important;
  margin: 0 auto;
}
.home .card-header{
  text-align: center;
}
.home .card{
  background-color: ghostwhite;
}
.home .card h3{
  font-size: 25px;
  color: cadetblue;
}
.home .row a{
  color: cadetblue;
}
.home a:hover .card{
  background-color: azure;
}
.lang-menu{
  display: flex;
  justify-content: space-around;
  list-style: none;
  margin-top: 40px;
  padding: 0;
  margin-bottom: 0;
}
.lang-menu li{
  cursor: pointer;
  border: 1px solid #1976d2;
  width: 33.33%;
  text-align: center;
  padding: 10px;
  margin: 0 5px;
  border-radius: 4px;
}
.lang-menu li:hover, .lang-menu li.active{
  background-color: #1976d2;
  color: white;
}
.page.lang-menu li:hover, .page.lang-menu li.active{
  background-color: white;
  color: #1976d2;
}
.page.lang-menu li{
  padding: 10px 20px;
  border: 1px solid #fff;
  text-transform: uppercase;
}
.page.lang-menu{
  margin: 0 40px 0 0;
}
.home .card-body{
  height: 85px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.delete-modal {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 30px 40px 40px;
  text-align: center;
}
.delete-modal p{
  margin-bottom: 0;
}
.delete-modal .buttons{
  margin-top: 32px;
}
.delete-modal h2{
  margin-bottom: 1rem;
}
.delete-modal .icon{
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.delete-modal .icon svg{
  width: 120px;
  height: 120px;
}
.delete-modal .buttons button{
  margin: 0 10px;
}
.cancel-btn{
  background-color: #a8a8a8!important;
  border-radius: 3px!important;
}

.delete-btn{
  border-radius: 3px!important;
  background-color: #ee3535!important;
}
form.add-report{
  width: 1200px;
  overflow: auto;
}
table.add-report{
  width: 90%;
  position: relative;
}
.add-report .button-group{
  display: flex;
  justify-content: flex-end;
  height: 45px;
}
table.add-report th{
  width: 40%;
  vertical-align: middle;
}
table.add-report td, table.add-report tr{
  padding: 0!important;
  border: none!important;
  vertical-align: middle;
}
header.MuiPaper-elevation{
  z-index: 99999;
}
table.add-report td > div > div{
  border-radius: 0px!important;
}

.table.add-report td > div > label.MuiFormLabel-filled, table.add-report td > div > label.Mui-focused{
  background-color: white;
  padding: 0px 10px 0px 5px;
  margin: 0 -5px;
}
table.add-report td > div{
  width: 100%;
}
table.add-report td > div fieldset legend{
  background-color: white;
}
table.add-report td > div fieldset{
  margin: 0px -1px -1px -1px;
}
table.add-report th{
  border-collapse: collapse;
  border: 1px solid rgba(0, 0, 0, 0.23);
}
table.add-report tr:last-child fieldset{
  margin-bottom: -0.5px;
}
table.add-report th.products{
  border-top: none;
  border-bottom: none;
}
.add-product-btn{
  position: absolute;
  left: 100%;
  bottom: 33px;
}
.add-product-btn td{
  text-align: right;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: flex-end;
}
.add-product-btn td span{
  margin: 0!important;
}
.add-product-btn td button{
  max-width: 40px;
  min-width: 40px;
  height: 40px;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-product-btn td:first-child{
  margin-left: 5px;
}
.Toastify__toast-container{
  z-index: 999999!important;
}
.status1{
  padding: 5px 20px;
  background-color: #fce83a;
  margin-top: 10px;
  box-sizing: border-box;
  border-radius: 16px;
  color: #000;
  text-transform: uppercase;
  font-size: 14px;
}
.status2{
  padding: 5px 20px;
  background-color: #2dccff;
  margin-top: 10px;
  box-sizing: border-box;
  border-radius: 16px;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
}
.status3{
  padding: 5px 20px;
  background-color: #ff3838;
  margin-top: 10px;
  box-sizing: border-box;
  border-radius: 16px;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
}
.status4{
  padding: 5px 20px;
  background-color: #56f000;
  margin-top: 10px;
  box-sizing: border-box;
  border-radius: 16px;
  color: white;
  text-transform: uppercase;
  font-size: 14px;
}
.MuiDialog-paper{
  min-width: 500px;
}
.filter-block form{
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 80%;
  margin: 0px 0 40px;
}
.filter-block form > p span{
  margin-bottom: 10px;
}
.filter-block form > p{
  margin: 0 20px;
  display: flex;
  flex-direction: column;
}
.filter-block .filter-btn{
  height: 55px;
  margin-top: auto;
  margin-bottom: 1px;
  width: 160px;
  font-size: 16px;
  margin-left: 20px;
}
.filter-block form > p:last-child{
  margin-right: 0;
}
.filter-block form > p:first-child{
  margin-left: 0;
}
.file-upload-btn{
  max-width: 30%;
}
form.add-document{
  max-width: 600px;
}
form.add-document p {
  margin-bottom: 30px;
}
.big-form tr th, .big-form tr td, .big-form tr{
  border: 1px solid #7e7e7e;
}
.big-form tr th, .big-form tr td, .big-form td input{
  font-size: 14px;
}
.big-form tr td{
  color: #313131;
  font-weight: 600;
}
.big-form tr th{
  color: #313131;
  padding: 2px 5px;
  vertical-align: middle;
}
.big-form tbody tr > th{
  width: 800px!important;
}
.big-form tr td input{
  width: 100%;
  height: 25px;
  display: block;
  padding: 2px;
  background-color: #fff7e9!important;
  border-color: #fff7e9!important;
  font-weight: 500;
  font-size: 14px;
}
.big-form tr td .disable-input{
  background-color: #ceface!important;
  border-color: #ceface!important;
  cursor: pointer;
}
.big-form tr td{
  padding: 0;
  vertical-align: middle;
}
form.big-form{
  margin: 0 auto;
}
form.big-form .button-group{
  width: 100%;
  margin: 30px 0;
}
.download-excel{
  display: flex;
  margin-bottom: 30px;
  justify-content: flex-start;
}
.error-comment{
  width: 1200px;
  margin: 0 auto 20px;
}
.download-excel p{
  margin-bottom: 0;
}
.download-excel p > div{
  margin-top: 14px;
}
.download-excel button{
  margin-left: 20px;
  height: 55px;
  width: 140px;
  margin-top: auto;
}
.scan-block{
  width: 400px;
  height: 400px;
  background-color: peachpuff;
  margin: 0 auto;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.scan-block img{
  padding: 40px;
  width: 80%;
}
.scan-block > div{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.fingerprint.scanning::after {
  content: '';
  top: 0;
  left: 0;
  position: absolute;
  width: 200%;
  height: 20px;
  background-image: linear-gradient(to bottom,
  rgba(170, 184, 190, 0),
  rgba(170, 184, 190, .8));
  animation: scanning .8s linear infinite;
}

@keyframes scanning {
  100% { transform: translate(-100px, 500px); }
}
.inp-text{
  opacity: 0;
  position: absolute!important;
  bottom: 0;
  right: 0;
}
.Toastify__toast-body > div:last-child{
  text-align: left;
  font-size: 17px;
  font-weight: 500;
}
.MuiModal-root{
  z-index: 999999!important;
}
.flag{
  width: 25px;
  margin-right: 5px;
}
.filter-block.scan button{
  padding: 12px 30px;
  font-size: 16px;
  margin-bottom: 40px;
}
.scanNow .MuiDialog-paper{
  width: 500px!important;
}